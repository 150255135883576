import React from 'react';
import { CalculatorApp } from './components/CalculatorApp';

function App() {
    return (
        <React.Fragment>
            
            <CalculatorApp/>
        </React.Fragment>
    );
}

export default App;
